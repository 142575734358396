<template lang="pug">
div
  van-sticky
    van-search(v-model='searchkey', placeholder='项目名称/项目编号/订单名称/订单编号' @search="onSearch"  show-action shape="round")
      template(#action='')
        div(@click='onSearch') 搜索
    van-dropdown-menu(:close-on-click-outside='false',)
      van-dropdown-item(v-model='orderType', :options='option2')
      van-dropdown-item(ref='item' title='筛选')
        van-switch-cell(v-model='isfinish', title='查看未完成订单')
        van-cell(v-for='(item,index) of screeningField' :key='index')
          template(#title='')
            van-row.row(type='flex', justify='space-between')
              van-col(span='7') 
                | {{item.label}}
              van-col(span='8')
                van-field(:value='getData(screeningData[item.start_key])', placeholder='开始日期' @click='onSelectData(item.start_key)') 
              van-col(span='8')
                van-field(:value='getData(screeningData[item.end_key])', placeholder='结束日期' @click='onSelectData(item.end_key)') 
              van-col(span='1')
                van-icon(name='close' @click='onClear(item.start_key,item.end_key)')
        van-button(type='info', block='', @click='onScreening')
          | 确定
   
  pull-refresh-list(v-model='refreshing',
    ref="list"
    @update:list="list = $event"
    :getListMethod="getListData"
   )
    van-card.card(v-for='(order,index) of list' 
     :key='index'
      @click="$router.push({name:'eyasorderdetail', query: {id: order.id}})"
     )
      template(#num)
        h1.text-danger(v-if="calculateOrderStatus(order) === 'danger'") 延期
        h1.text-warning(v-if="calculateOrderStatus(order) === 'warning'") 即将过期
        h1.text-success(v-if="calculateOrderStatus(order) === 'success'") 已完成
      template(#price) 
        p(v-for="(field,index) of fields" 
          :key='index')
          span {{field.label}}:
            | {{order[field.key]}}
      template(#footer)   
        van-progress(:percentage='calculateRate(order.completion_rate)')
        p
  van-action-sheet(v-model='show') 
    van-datetime-picker(v-model='currentDate', type='date', title='选择年月日', 
      @confirm='onConfirmData', 
      @cancel='show=false')

</template>

<script>
import { Progress } from 'vant';

export default {
  components:{
    [Progress.name]: Progress,
  },
  data() {
    return {
      currentDate: new Date(),
      show: false,
      selectDataKey: '',
      searchkey: '',
      isfinish: false,
      orderType: '-id',
      option2: [
        { text: '默认排序', value: '-id' },
        { text: '交货日期', value: 'delivery_time' },
      ],
      screeningField: [
        {label: '下单日期:', start_key:'cycle_start_time', end_key:'cycle_end_time'},
        {label: '交货日期:', start_key:'delivery_start_time', end_key:'delivery_end_time'},
        {label: '开始日期:', start_key:'order_start_time', end_key:'order_end_time'},
        {label: '预计结束日期:', start_key:'estimated_start_time', end_key:'estimated_end_time'}
      ],
      screeningData: {
        cycle_start_time: '',
        cycle_end_time: '',
        delivery_start_time: '',
        delivery_end_time: '',
        order_start_time: '',
        order_end_time: '',
        estimated_start_time: '',
        estimated_end_time: '',
      },
      refreshing: false,
      fields:[
        { key: 'name', label: '订单名称'},
        { key: 'project_name', label: '项目名称'},
        { key: 'cycle_time', label: '下单日期'},
        { key: 'delivery_time', label: '交货日期'},
        { key: 'none', label: '完成率'},
      ],
      list:[
      //  {
      //     id: "47",
      //     order_poi_ofc_order: "0",
      //     completion_rate: "1.00",
      //     name: "19-11-205",
      //     number: "19-11-205",
      //     project_name: "19-11-205",
      //     project_number: "19-11-205",
      //     cycle_time: "1574179200000",
      //     delivery_time: "1574179200000",
      //     start_time: "1574179200000",
      //     expected_end_time: "1574179200000",
      //     end_time: "0",
      //     warning_time: "1574179200000",
      //     process: "设计,板式车间-生产,实木车间-生产,软体车间-生产,油漆车间-生产,入库",
      //     com_id_poi_companys: "96",
      //     uid_poi_company_admin: "130",
      //     create_time: "1576981597030",
      //     update_time: "1576981597030",
      //   },
      //   {
      //     id: "48",
      //     order_poi_ofc_order: "0",
      //     completion_rate: "0.10",
      //     name: "19-11-205",
      //     number: "19-11-205",
      //     project_name: "19-11-205",
      //     project_number: "19-11-205",
      //     cycle_time: "1574179200000",
      //     delivery_time: "1574179200000",
      //     start_time: "1574179200000",
      //     expected_end_time: "1574179200000",
      //     end_time: "0",
      //     warning_time: "1574179200000",
      //     process: "设计,板式车间-生产,实木车间-生产,软体车间-生产,油漆车间-生产,入库",
      //     com_id_poi_companys: "96",
      //     uid_poi_company_admin: "130",
      //     create_time: "1576981597030",
      //     update_time: "1576981597030",
      //   },
      //   {
      //     id: "49",
      //     order_poi_ofc_order: "0",
      //     completion_rate: "0.30",
      //     name: "19-11-205",
      //     number: "19-11-205",
      //     project_name: "19-11-205",
      //     project_number: "19-11-205",
      //     cycle_time: "1588835019000",
      //     delivery_time: "1588835019000",
      //     start_time: "1588748619000",
      //     expected_end_time: "1588921419000",
      //     end_time: "0",
      //     warning_time: "1588835019000",
      //     process: "设计,板式车间-生产,实木车间-生产,软体车间-生产,油漆车间-生产,入库",
      //     com_id_poi_companys: "96",
      //     uid_poi_company_admin: "130",
      //     create_time: "1576981597030",
      //     update_time: "1576981597030",
      //   },
      ]
    }
  },
  computed: {
    companyInfo(){
      return this.$store.state.companyInfo
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
  },
  watch:{
    orderType(){
      this.$refs.list.refresh()
    },
  },
  methods: {
    getData(value){
      let result = this.dataFormat(value)
      return result === 'Invalid date' ? '' : result
    },
    async getListData(params) {
      try {
        if(this.isfinish)  params.not_finish = 'yes'
        let param = {
          ...params,
          order: this.orderType,
          searchkey: this.searchkey,
          search: this.searchkey,
          cycle_start_time: this.screeningData.cycle_start_time,
          cycle_end_time: this.screeningData.cycle_end_time,
          delivery_start_time: this.screeningData.delivery_start_time,
          delivery_end_time: this.screeningData.delivery_end_time,
          order_start_time: this.screeningData.order_start_time,
          order_end_time: this.screeningData.order_end_time,
          estimated_start_time: this.screeningData.estimated_start_time,
          estimated_end_time: this.screeningData.estimated_end_time,
          where: {
            com_id_poi_companys : this.companyInfo.id,
            seller_poi_sell_users: this.sellerId
          }
        }
        let res = await this.$api.GetEyasOrders(param)
        this.list = this.list.concat(res.data.items.map((order) => {
          return {
            ...order,
            cycle_time: this.dataFormat(order.cycle_time),
            delivery_time: this.dataFormat(order.delivery_time),
          }
        }))
        return res.data.items.length
      }catch(ex) {
        this.$toast(this.$error(ex))
        return 0
      }
    },
    onClear(start_key,end_key) {
       this.screeningData[start_key] = ''
      this.screeningData[end_key] = ''
    },
    onSelectData(obj) {
      this.selectDataKey = obj
      this.show = true
      this.currentDate = this.screeningData[obj] != '' ?  new Date(this.screeningData[obj]) : new Date()
    },
    onConfirmData(value) {
      let spanTiem = value.valueOf()  // 时间戳
      this.screeningData[this.selectDataKey] = spanTiem
      this.show = false
    },
    onSearch() {
      this.$refs.list.refresh()
    },
    onClick() {
      this.$refs.list.refresh()
    },
    onScreening() {
      this.$refs.item.toggle();
      this.$refs.list.refresh()
    },
    calculateRate(rate) {
      let value = parseInt(rate * 100)
      return value
    },
    /**
     * 计算订单状态，延期返回danger,预警返回warning,完成返回success
     * @param {Object} order 订单数据
     */
    calculateOrderStatus(order) {
      if (parseInt(order.completion_rate) === 1) {
        return 'success'
      }
      let today = new Date()
      let datestamp = today.getTime()
      if (order.expected_end_time !== '0') {
        if (datestamp > order.expected_end_time && order.completion_rate < 1) {
          return 'danger'
        }
        if (order.warning_time !== '0' && datestamp >= order.warning_time &&  datestamp <= order.expected_end_time && order.completion_rate < 1) {
          return 'warning'
        }
      }
    },
  },
  mounted() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      },
      // rightText: '个人中心',
      // rightArrow: false,
      // onClickRight: ()=> {
      //   this.$router.push('person')
      // },
    })
  },
}
</script>
<style scoped>
.list{
  position: relative;
  /* height: 100%; */
  /* bottom: 40px; */
}
.card{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;margin:10px 15px;flex-direction:column;min-width:0;word-wrap:break-word;background-color:#fff;background-clip:border-box;border:1px solid rgba(0,0,0,.125);border-radius:.25rem}
.text-danger{color:#dc3545!important}
.text-warning{color:#ffc107!important}
.text-success{color:#28a745!important}

.row {
  display: flex;
  align-items: center;
}
</style>


